import React, { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import toast, { Toaster } from "react-hot-toast";
import ConnectWalletButton from "../ConnectWalletButton";
import {
  useAccount,
  useContractWrite,
  useContractRead,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { formatUnits } from "viem";
import presaleAbi from "../../abi/presale.json";
import { contractAddr, chainId, symbol } from "../../config";

const ClaimToken = () => {
  const { address } = useAccount();

  const getUserTotalTokens = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "userTotalTokens",
    enabled: !!address,
    args: [address],
    watch: true,
    chainId: chainId,
  });

  const totalToken = new BigNumber(getUserTotalTokens.data || 0)
    .dividedBy(new BigNumber(10).pow(18))
    .toFixed(3);

  const balance = new BigNumber(getUserTotalTokens.data || 0)
    .dividedBy(new BigNumber(10).pow(18))
    .toFixed(3);

  const { config } = usePrepareContractWrite({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "claimTokens",
    enabled: !!address,
    chainId: chainId,
  });

  const { data, write } = useContractWrite(config);
  const { isLoading, isSuccess, isError } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        <div className="text-center py-2">
          Success! Tokens have been successfully claimed.
          <div>
            <a
              style={{ color: "#fff" }}
              href={`https://bscscan.com/tx/${data?.hash}`}
              target="_blank"
            >
              View On Bscscan
            </a>
          </div>
        </div>
      );
      const timeout = setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(
        <div className="text-center py-2">
          Error! Something went wrong. Please try again.
        </div>
      );
      const timeout = setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [isError]);

  return (
    <>
      <h4 className="text-center">Claim Your Tokens</h4>
      <div className="text-center">
        {address ? (
              <><p>
            <strong>Total Tokens Purchased:</strong> {totalToken} {symbol}
          </p><p>
              <strong>Available for Claim:</strong> {balance} {symbol}
            </p><button
              type="button"
              className="bg-btn"
              style={{ width: "100%" }}
              disabled={!write || isLoading}
              onClick={write}
            >
              {isLoading ? "Claiming..." : "Claim Now"}
            </button></>
        ) : (
          <ConnectWalletButton />
        )}
      </div>
    </>
  );
};

export default ClaimToken;
