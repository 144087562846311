import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import {
  useAccount,
  useContractWrite,
  useContractRead,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import presaleAbi from "../abi/presale.json";
import Copy from "../assets/currency/copy.svg";
import { parseUnits, formatEther, formatUnits } from "viem";
import ConnectWalletButton from "../components/ConnectWalletButton";
import ClipboardJS from "clipboard";
import toast, { Toaster } from "react-hot-toast";
import {
  tokenAdd,
  tokenEthAdd,
  tokenUsdtAdd,
  contractAddr,
  chainId,
  name,
  symbol,
  decimal,
} from "../config";

const ReferralCard = () => {
  const { address } = useAccount();

  useEffect(() => {
    const handleCopy = (trigger, result) => {
      const feedback = document.querySelector(".copy-feedback");
      if (result === "success") {
        feedback.textContent = "Copied to Clipboard";
      } else {
        feedback.textContent = "Failed to Copy";
      }
      feedback.style.display = "block";
      setTimeout(() => {
        feedback.style.display = "none";
      }, 1000);
    };

    const clipboard = new ClipboardJS(".copy-clipboard")
      .on("success", (event) => {
        handleCopy(event.trigger, "success");
        event.clearSelection();
      })
      .on("error", (event) => {
        handleCopy(event.trigger, "fail");
      });

    const modalClipboard = new ClipboardJS(".copy-clipboard-modal", {
      container: document.querySelector(".modal"),
    })
      .on("success", (event) => {
        handleCopy(event.trigger, "success");
        event.clearSelection();
      })
      .on("error", (event) => {
        handleCopy(event.trigger, "fail");
      });

    return () => {
      clipboard.destroy();
      modalClipboard.destroy();
    };
  }, []);

  const getRefCount = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "referralsCount",
    args: [address],
    watch: true,
    enabled: !!address,
    chainId: chainId,
  });

  const getRefAmount = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "refTotalAmounts",
    args: [address],
    watch: true,
    enabled: !!address,
    chainId: chainId,
  });

  const getReferRate = useContractRead({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "referRate",
    watch: true,
    chainId: chainId,
  });

  const { config } = usePrepareContractWrite({
    address: contractAddr,
    abi: presaleAbi,
    functionName: "claimReferBonus",
    enabled: !!address,
    chainId: chainId,
  });

  const { data, write } = useContractWrite(config);
  const { isLoading, isSuccess, isError } = useWaitForTransaction({
    hash: data?.hash,
  });

  const refAmount = new BigNumber(getRefAmount.data);
  const resultAmount = isNaN(refAmount)
    ? 0
    : new BigNumber(refAmount).dividedBy(new BigNumber(10).pow(18)).toFixed(3);
  const refCount = new BigNumber(getRefCount.data);
  const resultCount = isNaN(refCount) ? 0 : new BigNumber(refCount).toNumber();
  const referBonus = new BigNumber(getReferRate.data).toNumber();

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        <div className="text-center py-2">
          Success! Tokens have been successfully claimed.
          <div>
            <a
              style={{ color: "#fff" }}
              href={`https://bscscan.com/tx/${data?.hash}`}
              target="_blank"
            >
              View On Bscscan
            </a>
          </div>
        </div>
      );
      const timeout = setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(
        <div className="text-center py-2">
          Error! Something went wrong. Please try again.
        </div>
      );
      const timeout = setTimeout(() => {
        toast.dismiss();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [isError]);

  return (
    <div className="cardReferral">
      <div className="card-body">
        <h2 className="text-center mb-4">Referral Program</h2>
        <div className="row mb-3">
          <div className="col-6">
            <div className="fw-bold">Your Referrals</div>
            <div>{resultCount}</div>
            <div className="fw-bold">Your Earnings</div>
            <div>
              {resultAmount} {symbol}
            </div>
          </div>
        </div>
        <h4 className="text-center">Claim Your Referrals Earnings</h4>
        <div className="text-center">
        {address ? (
          <button
                type="button"
                className="bg-btn"
                style={{ width: "100%" }}
                disabled={!write || isLoading}
                onClick={write}
              >
                {isLoading ? "Claiming..." : "Claim Referrals Earnings"}
              </button>

        ) : (
         <ConnectWalletButton />

        )}
        
              </div>
      </div>
    </div>
  );
};

export default ReferralCard;
